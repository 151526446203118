const modalState = reactive({
  isOpen: false,
})

function showModal() {
  const { $gtm: { sendAnalytics } } = useNuxtApp()

  modalState.isOpen = true
  sendAnalytics(
    'rating_certificate_form_submit_lead',
    'click',
    'Rating',
    'Получить сертификат',
  )
}
const hideModal = () => modalState.isOpen = false

export {
  hideModal,
  showModal,
  modalState,
}
